body {
  margin: 0;
  /* overflow-x: hidden; */
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  overflow-x: hidden;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
}

.Toastify__toast--default {
  background: #fff;
  color: #fff;
  font-weight: normal;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0px 1px 5px rgba(248, 175, 175, 0.1) !important;
  font-size: 14px !important;
}
.Toastify__toast--info {
  background: #38c7d5;
  color: #fff;
  font-weight: normal;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0px 1px 5px rgba(248, 175, 175, 0.1) !important;
  font-size: 14px !important;
}
.Toastify__toast--success {
  background: #449d48;
  color: #fff;
  font-weight: normal;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0px 1px 5px rgba(248, 175, 175, 0.1) !important;
  font-size: 14px !important;
}
.Toastify__toast--warning {
  background: #cca300;
  color: #fff;
  font-weight: normal;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0px 1px 5px rgba(248, 175, 175, 0.1) !important;
  font-size: 14px !important;
}
.Toastify__toast--error {
  background: #f33223;
  color: #fff;
  font-weight: normal;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0px 1px 5px rgba(248, 175, 175, 0.1) !important;
  font-size: 14px !important;
}

.react-tel-input .form-control {
  max-width: 500px !important;
  width: auto;
  height: 50px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

@font-face {
  font-family: "BahijJanna";
  src: local("BahijJanna"), url(./fonts/BahijJanna.ttf) format("truetype");
}
