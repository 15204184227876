.App {
  text-align: center;
  box-sizing: border-box;
  margin: auto;
  overflow-x: hidden;
  min-height: 100vh;
  position: relative;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

a {
  text-decoration: none;
  color: inherit;
}

a:active {
  text-decoration: none;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
